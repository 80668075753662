<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div :hidden="hidden">
        <div class="d-flex justify-content-end">
          <b-button
            variant="outline-primary"
            size="sm"
            @click="ExportFomatExcel()"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Exporter
          </b-button>

          <b-button
            variant="outline-primary"
            size="sm"
            class="ml-2"
            @click="ExportAllToFomatExcel()"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Tout Exporter
          </b-button>

          <!-- bouton export end -->
        </div>

        <b-card no-body title="" class="mt-1">
          <div class="my-2 mx-1 row">
            <!-- search start -->
            <div class="col-lg-2 col-md-3 mt-2 mt-lg-2">
              <b-form-input
                id="filter-input"
                v-model="valueSearch"
                type="search"
                placeholder="Recherche"
                @input="filterValue"
              >
              </b-form-input>
            </div>
            <!-- search end -->

            <!-- date start -->
            <div class="col-lg-2 col-md-3 mt-2 mt-lg-2">
              <b-form-input
              placeholder="Date de début"
              v-model="valueStart"
              :date-format-options="dateFormatOptions"
              type="date"
              class="d-inline-block"
              @input="filterValue"
              />
              <label>Date de début</label>
              <span
                class="text-danger"
                style="font-size: 0.9em; margin-top: 5px; margin-left: 8px"
              >
                {{ errorValueStart }}
              </span>
            </div>

            <div class="col-lg-2 col-md-3 mt-2 mt-lg-2">
              <b-form-input
              placeholder="Date de fin"
              v-model="valueEnd"
              :date-format-options="dateFormatOptions"
              type="date"
              class="d-inline-block"
              @input="filterValue"
              />
              <label>Date de fin</label>
            </div>
            <!-- date end -->

            <!-- select importateur start -->
            <b-form-select
              v-model="selectedAssociation"
				      @change="checkAssociation"
              :options="associations"
              value-field="name"
							text-field="name"
              class="col-lg-2 col-md-3 mt-2 mt-lg-2"
              @input="filterValue"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  style="color: #b4b7bd; text-align: center"
                  >-- Association --</b-form-select-option
                >
              </template>
            </b-form-select>

            <!-- solde start -->
            <div id="solde-input" class="col-lg-2 col-md-3 mt-2 mt-lg-2">
              <b-form-input
                v-model="beneficesTotal"
                type="text"
                disabled
                placeholder="Bénéfice"
              >
              </b-form-input>
            </div>

            <div id="solde-input" class="col-lg-2 col-md-3 mt-2 mt-lg-2">
              <b-form-input
                v-model="dedomagementsTotal"
                type="text"
                disabled
                placeholder="Solde"
              >
              </b-form-input>
            </div>


            <div class="col-lg-2 col-md-3 mt-2 mt-lg-2 ml-0">
                          <b-form-select
                                      v-model="selectedTrie"
                                      :options="trieDate"
                                      @change="changeTrie"
                                  >
                          </b-form-select>
                          <label>Trier Par Date</label>
             </div>
                    
            <!-- solde end -->

            <!-- select importateur end -->
          </div>

          <!-- table start -->
          <b-table
            v-model="excelDedomag"
            id="my-table"
            responsive
            :fields="headers"
            @filtered="onFiltered"
            :items="dedomagements"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-function="filterFunction"
            show-empty
            class="border-bottom"
          >
          <template #cell(association)="data">
              <a
                  class="hoverSuccess"
                  @click="showAssociation(data.item.manifeste.association)">
                {{ data.item.association }}
              </a>
            </template>
          <template #cell(vehicule)="data">
              {{
                data.item.vehicule
                  ? data.item.vehicule.marque + "-" + data.item.vehicule.couleur  + "-" + data.item.vehicule.annee
                  : "-----"
              }}
            </template>
            <template class="btn btn-info btn-sm" #cell(num_chassis)="data">
              {{
                data.item.vehicule
                  ? data.item.vehicule.numero_chassis
                  : "-----"
              }}
            </template>
            <template #cell(benefice)="data">
              {{
                data.item.benefice
                  ? $thousandSeparator(data.item.benefice)
                  : "-----"
              }}
            </template>
            <template #cell(montant_vente)="data">
              {{
                data.item.montant_vente
                  ? $thousandSeparator(data.item.montant_vente)
                  : "-----"
              }}
            </template>
            <template #cell(total_percu)="data">
              {{
                data.item.total_percu
                  ? $thousandSeparator(data.item.total_percu)
                  : "-----"
              }}
            </template>
            <template #cell(created_at)="data">
              <span>
                {{ data.item.created_at ? data.item.created_at : "----" }}
              </span>
            </template>
            <template #cell(dedomagement)="data">
              {{
                data.item.dedomagement
                  ? $thousandSeparator(data.item.dedomagement)
                  : "-----"
              }}
            </template>

            <!-- iconAction start -->
            <template #cell(actions)="data">
              <div>
                <b-button
                  v-if="
                    data.item.statut == 0 &&
                    $hasFonction(user, 'Dédommager vente véhicule')
                  "
                  size="sm"
                  variant="flat-success"
                  class="btn-icon"
                  @click="dedomagerDialog(data.item)"
                >
                  <feather-icon icon="RepeatIcon" />
                </b-button>
              </div>
            </template>
            <!-- iconAction end -->

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>
              <span class="text-nowrap ml-2" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span>
	                <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
                  </span>
              <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>
            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              class="pagination"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>

        <!-- modal edit start  -->
        <b-modal
          id="ded-modal"
          v-model="dialogDedomager"
          title="Effectuer un dédomagement"
          @hidden="resetDialog"
          @ok-disabled="isOperating"
          centered
		  hide-footer
        >
          <b-form
            class="mt-1"
            ref="editedItem"
            @submit.stop.prevent="dedomager"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="montant"
                rules="required"
              >
                <label for="montant">Montant</label>
                <cleave
                  id="montant"
                  v-model="editedItem.montant"
                  :state="errors.length > 0 ? false : null"
                  class="form-control"
				  placeholder="0"
                  :raw="false"
                  :options="numberFormat"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="obs"
                rules="required"
              >
                <label for="obs">Observation</label>
                <b-form-input
                  id="obs"
                  type="text"
				  placeholder="Observation"
                  v-model="editedItem.obs"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

			<b-form-group class="mb-0">
				<b-button
					variant="outline-secondary"
					type="reset"
					class="mt-2 mr-1"
					@click="dialogDedomager=false"
				>
					Annuler
				</b-button>

				<b-button
					v-if="etatButtonEdit"
					variant="primary"
					class="mt-2"
					:disabled="isOperating"
					@click="dedomager"
				>
					Enregistrer
				</b-button>

				<b-button 
					v-else variant="primary"
					class="mt-2"
				>
					<b-icon-arrow-clockwise
						animation="spin"
						font-scale="1"
					></b-icon-arrow-clockwise>
					En cours
				</b-button>
			</b-form-group>
          </b-form>
        </b-modal>
        <!-- modal edit end -->
      </div>

	  <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Cleave from "vue-cleave-component";
import { ModelListSelect } from "vue-search-select";
// import { FormWizard, TabContent } from 'vue-form-wizard'
import FormWizard from "@core/components/vue-form-wizard/src/components/FormWizard.vue";
import TabContent from "@core/components/vue-form-wizard/src/components/TabContent.vue";
import "@core/components/vue-form-wizard/dist/vue-form-wizard.min.css";
// import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import {
  BIconPlus,
  BCard,
  BTable,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BCardText,
  BBadge,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BIconArrowClockwise,
  BTooltip,
  BInputGroupAppend,
  BInputGroup,
  BFormDatepicker,
  BIconCircleFill,
  BIconCartPlus,
  VBTooltip,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    Cleave,
    ModelListSelect,
    FormWizard,
    TabContent,
    VueSweetalert2,
    VueGoodTable,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BTooltip,
    BFormInput,
    BInputGroup,
	BIconArrowClockwise,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BBadge,
    BIconCartPlus,
    BFormDatepicker,
    BIconCircleFill,
    BFormSelectOption,
    VBTooltip,
    BIconFileEarmarkArrowDownFill,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
	  etatButtonEdit: true,
      numberFormat: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          delimiter: ' ',
      },
      dateFormatOptions: { day: "numeric", month: "numeric", year: "numeric" },
      dialogDedomager: false,
      search: "",

      dedomagements: [],
      selectedAssociation: "",
      associations: [
        {
          label: "ASSOCIATION",
          options: [],
        },
      ],
      excelDedomag: [],
      associes: [],
      editedItem: {},
      valueStart: null,
      valueSearch:  "",
      allRows: 0,
      filter: "",
      valueEnd: null,
      errorValueStart: "",
      hidden: true,
	  hiddenEmpty: true,
      isOperating: false,
      hiddenLoader: false,
      lastSelectedAssociation: "",
      trieDate: [
        { text: "Croissant", value: "croissant" },
        { text: "Décroissant", value: "decroissant" },
      ],
      selectedTrie: null,
      perPage: 10,
      currentPage: 1,
      pageOptions: [],
      headers: [
        { label: "Date d'annulation", key: "updated_at", sortable: false },
        { label: "Clients", key: "client_name", sortable: true },
        { label: "Association", key: "association", sortable: true },
        { label: "Véhicule", key: "vehicule", sortable: true },
        { label: "N. CHASSIS", key: "num_chassis", sortable: true },
        { label: "Montant vente", key: "montant_vente", sortable: true },
        { label: "Total perçu", key: "total_percu", sortable: true },
        { label: "Total démarcheur", key: "old_montant_demarcheur", sortable: true },
        { label: "Montant dédomagé", key: "dedomagement", sortable: true },
        { label: "Bénéfice", key: "benefice", sortable: true },
        { label: "Observations", key: "observation", sortable: true },
        { label: "Actions", key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),
    totalRows() {
      // Set the initial number of items
      this.pageOptions = [10, 25, 50, 100, { value: "10000000000000000000000000", text: "Tout" } ]
      return this.allRows;
    },

    beneficesTotal() {
      // Je vais essayer de passer trois niveaux de filtres, let's-go !!!
      let ben = 0;
      if (!this.dedomagements) this.dedomagements = [];
      let dedomagements = this.dedomagements;

      /*1 - Passer le premier niveau, verifier si une date minimale est founie, et remplacer le contenu
      du tableau par la liste des élements verifiants la condition */
      if (this.convert(this.startDateFilterValue)) {
        dedomagements = dedomagements.filter(
          (operation) =>
            operation.updated_at >= this.convert(this.startDateFilterValue)
        );
      }

      /*2 - Passer le deuxième niveau, verifier si une date manimale est founie, et remplacer le contenu
      du tableau par la liste des élements verifiants la condition */
      if (this.convert(this.endDateFilterValue)) {
        dedomagements = dedomagements.filter(
          (operation) =>
            operation.updated_at <= this.convert(this.endDateFilterValue)
        );
      }

      /*3 - Passer le troisième niveau, verifier si un founisseur est founi, et remplacer le contenu
      du tableau par la liste des élements verifiants la condition */
      if (this.FsrFilterValue) {
        dedomagements = dedomagements.filter(
          (operation) => operation.fournisseur == this.FsrFilterValue
        );
      }

      // Sommer enfin les bénefices du tablaeu final obtenu
      dedomagements.forEach((element) => {
        ben += element.benefice;
      });

      return this.$thousandSeparator(ben);
    },

    dedomagementsTotal() {
      let ded = 0;

      if (!this.dedomagements) this.dedomagements = [];
      let dedomagements = this.dedomagements;
      if (this.FsrFilterValue) {
        dedomagements.forEach((element) => {
          if (this.FsrFilterValue == element.fournisseur) {
            if (element.dedomagement == 0) ded += element.total_percu;
          }
        });
      } else {
        dedomagements.forEach((element) => {
          if (element.dedomagement == 0) ded += element.total_percu;
        });
      }
      return this.$thousandSeparator(ded);
    },
  },
  created() {
    this.associesData();
    this.initialize();
     this.getAssociesAcheteurList()
    this.selectedTrie = "decroissant"
  },
  methods: {
	etatAdd() {
      this.add = true;
      this.hiddenEmpty = true;
    },

    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length
        this.currentPage = 1
      },


    getAssociesAcheteurList(){
				this.$http.get('/associations')
					.then(res => {
						this.associations[0].options = res.data.data.reverse()
					})
					.catch(e => {
					})

		},
    filterValue(val) {
      this.filter = " ";
    },

    dateFilter(start, end, value) {
      this.filter = " ";
      if (!start && !end) {
        return true;
      } else if (start && !end) {
        if (value >= start) {
          return true;
        } else {
          return false;
        }
        //return value >= start;
      } else if (!start && end) {
        return value <= end;
      }
      return value >= start && value <= end;
    },
    
    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, '-')
      var parts = dateStr.split('-')
      var date = new Date(parts[2], parts[1] -1, parts[0])
      return date 
    },


    async checkAssociation() {
      console.log('Association'+ this.associationComptes)
      this.associationComptes = this.dedomagements.filter((compte) => {
        if (
          compte.association === this.selectedAssociation
        ) {
          return true;
        }
      });
    },

    
    filterFunction(row, DateFilter) {

      if (this.selectedAssociation == null) {
      
      } else {
        this.checkAssociation();
      }
        var start = null
        var end = null
        if(this.valueStart){
          var parts = this.valueStart.split('-')
          start = new Date(parts[0], parts[1] -1, parts[2])
        }
        if(this.valueEnd){
          var parts = this.valueEnd.split('-')
          end = new Date(parts[0], parts[1] -1, parts[2])
        }
        var valueDate = row.created_at.slice(0,10);
        var value = new Date(this.toDate(valueDate))

        if
        (this.selectedAssociation != null && this.selectedAssociation != '' &&
         this.selectedAssociation != row.association) {
          return false
        }
        else if(row.association.toString().toLowerCase().indexOf(this.valueSearch.toLowerCase()) == -1
                && row.client_name.toString().toLowerCase().indexOf(this.valueSearch.toLowerCase()) == -1
                && row.montant_vente.toString().toLowerCase().indexOf(this.valueSearch.toLowerCase()) == -1
                && row.total_percu.toString().toLowerCase().indexOf(this.valueSearch.toLowerCase()) == -1){
           return false
        }
        else {
          return this.dateFilter(start, end, value);
        }

    },
    isNotValid(montant) {
      if (montant) {
        return false;
      }
      return true;
    },

    async associesData() {
      await this.$http.get("/associes").then((response) => {
        this.associes.push(response.data.data);
      });
    },

    
    changeTrie() {
        if(this.selectedTrie == "croissant") {
          this.dedomagements = this.dedomagements.reverse();
        }
        if(this.selectedTrie == "decroissant") {
        this.dedomagements = this.dedomagements.reverse();
        }
      },

    async initialize() {
      await this.$http.get("/dedomagements").then((response) => {
        if (response.status) {
            this.hiddenLoader = true;
        }

		    this.dedomagements = response.data.data;

          

    
    this.allRows = this.dedomagements.length
        this.hiddenLoader = true;
        this.hidden = false;
        if (this.dedomagements) {
			this.dedomagements.forEach((element) => {
				element.client_name = element.client.name;
          	});
        }

        this.dedomagements.sort(function(a,b){
            return new Date(b.updated_at) - new Date(a.updated_at);
          });
		if (this.dedomagements.length) {
            this.hidden = false;
            this.hiddenEmpty = true;
        } else {
            this.hidden = true;
            this.hiddenEmpty = false;
        }
      });
    },

    dedomagerDialog(item) {
      this.editedItem.id = item.vehicule.id;
      this.editedItem.client = item.client.id;
      this.editedItem.montant_vente = item.montant_vente;
      this.editedItem.created_at = item.created_at;

      this.dialogDedomager = true;
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    resetDialog() {
      this.editedItem = {};
      this.dialogDedomager = false;
    },

    convert(date) {
      if (date) {
        date = new Date(date);
      }
      return this.reformatDate(date);
    },

    toDate(dateStr) {
      dateStr = dateStr.replace(/\//g, "-");
      var parts = dateStr.split("-");
      var date = new Date(parts[2], parts[1] - 1, parts[0]);
      return this.reformatDate(date);
    },

    reformatDate(date) {
      if (date) {
        let annee = date.getFullYear();
        let mois = date.getMonth() + 1;
        let jour = date.getDate();
        date = annee + "-" + mois + "-" + jour;
        return date.toString();
      }
      return Date.parse(date);
    },

    dedomager(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isOperating = true;
      this.editedItem.montant = this.$thousandFormater(this.editedItem.montant)

	  	if(this.editedItem.montant && this.editedItem.obs){
		   this.etatButtonEdit = false
			
			  this.$http
			    .post("/dedomagements", this.editedItem)
			    .then((result) => {
			      this.isOperating = false;
			      if (result.data.success == true) {
					this.etatButtonEdit = true
			        this.showToast("Opération éffectuée", "success");
			        this.resetDialog();
			        this.initialize();
              
			      } else {
					this.etatButtonEdit = true
			        let error = result.data.message;
			        if (error.transaction_error) {
			          this.showToast(error.transaction_error[0], "danger");
			        } else {
			          this.showToast(
			            error[Object.keys(transaction_error)[0]][0],
			            "danger"
			          );
			        }
			      }
			    })
			    .catch((err) => {
				  this.etatButtonEdit = true
			      this.isOperating = false;
			      let error = err.response.data.message;
			      if (error.transaction_error) {
			        this.showToast(error.transaction_error[0], "danger");
			      } else {
			        this.showToast(err.response.data.message, "danger");
			      }
			    });
		} else {
			if (!this.editedItem.montant) {
				// code
			} else {
				// code
			}

			if (!this.editedItem.obs) {
				// code
			} else {
				// code
			}
		}
    },

	ExportFomatExcel() {
		let { json2excel, excel2json } = require("js2excel");
		// let headers = this.headers
		let data = [];
		let obj = {};

		this.excelDedomag.forEach((element) => {
			obj = {
				"DATE D'ANNULATION": element.created_at,
				"CLIENTS": element.client_name,
				"ASSOCIATION": element.association,
				"MONTANT VENTE": element.montant_vente ? element.montant_vente : 0,
				"TOTAL PERÇU": element.total_percu ? element.total_percu : 0,
				"MONTANT DÉDOMAGÉ": element.dedomagement ? element.dedomagement : 0,
				"BÉNÉFICE": element.benefice ? element.benefice : 0,
				"OBSERVATIONS": element.observation ? element.observation : 0,
			};

			data.push(obj);
		});

		if (data) {
			try {
				json2excel({
					// headers,
					data,
					name: "FlashCar - Liste des Dédomages",
					formateDate: "yyyy/mm/dd",
				});
			} catch (e) {
				console.log(e);
			}
		}
	},


  ExportAllToFomatExcel() {
		let { json2excel, excel2json } = require("js2excel");
		// let headers = this.headers
		let data = [];
		let obj = {};

		this.dedomagements.forEach((element) => {
			obj = {
				"DATE D'ANNULATION": element.created_at,
				"CLIENTS": element.client_name,
				"ASSOCIATION": element.association,
				"MONTANT VENTE": element.montant_vente ? element.montant_vente : 0,
				"TOTAL PERÇU": element.total_percu ? element.total_percu : 0,
				"MONTANT DÉDOMAGÉ": element.dedomagement ? element.dedomagement : 0,
				"BÉNÉFICE": element.benefice ? element.benefice : 0,
				"OBSERVATIONS": element.observation ? element.observation : 0,
			};

			data.push(obj);
		});

		if (data) {
			try {
				json2excel({
					// headers,
					data,
					name: "FlashCar - Liste des Dédomages",
					formateDate: "yyyy/mm/dd",
				});
			} catch (e) {
				console.log(e);
			}
		}
	},

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.b-form-date-controls {
  margin-right: 38%;
}

.vue-form-wizard {
  box-shadow: 0px 0px;
  margin-bottom: 0px !important;
}

.wizard-card-footer {
  height: 20px;
}

.wizard-footer-left .wizard-btn {
  //   color: #27295c !important;
  //   border: 1px solid #27295c !important;
  //   background-color: white !important;
  padding-left: 1px !important;
  padding-top: 1px !important;
  padding-right: 15px !important;
  padding-bottom: 1px !important;
}

.wizard-footer-right .wizard-btn {
  color: #27295c !important;
  border: 1px solid #27295c !important;
  background-color: white !important;
  padding-left: 15px !important;
  padding-top: 1px !important;
  padding-right: 0px !important;
  padding-bottom: 1px !important;
}

.wizard-card-footer {
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .annuler {
    margin-bottom: 5px;
  }

  .blockSearch {
    width: 350px !important;
  }

  .search {
    width: 250px !important;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 800px) {
  /* .annuler {
			margin-bottom: 5px;
		} */

  .form {
    margin-left: 10%;
  }

  .formRow .form-group {
    width: 500px;
  }

  .blockForm {
    margin-left: 0px !important;
  }

  .blockSearch {
    width: 80% !important;
  }

  .search {
    width: 250px !important;
    margin-bottom: 10px;
  }

  .BlockbButton {
    padding-right: 0px !important;
    margin-right: 40px !important;
  }
}

@media screen and (max-width: 1000px) {
  #solde-input {
    margin-left: 20px !important;
  }

  .form {
    margin-left: 12%;
  }

  .fonction {
    width: 520px;
  }

  .BlockbButton {
    padding-right: 130px !important;
  }
}
</style>
